<template>
  <div>
    <div class="px-4 pt-2 bg-white shadow sticky top-0 z-10">
      <sqr-page-header :title="campaign.name" v-if="campaign">
        <sqr-button
          icon="trash"
          color="white"
          class="mr-1"
          @click="removeConfirm()"
          :loading="removing[campaign.id]"
        />
        <sqr-button
          icon="clone"
          color="white"
          class="mr-1"
          @click="clone()"
          :loading="cloning"
        />
        <sqr-button icon="edit" color="white" @click="rename()" class="mr-1" />
        <sqr-button
          icon="paper-plane"
          label="Send"
          @click="send"
          :loading="sending"
        />
      </sqr-page-header>
      <nav-tabs :tabs="tabs" class="-mx-4" />
    </div>
    <sqr-progress v-show="loading" />
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { fromPairs, omit } from 'ramda';
const { compile } = require('handlebars');

import broker from '../broker';

import SqrPageHeader from '@/sqrd-ui/SqrPageHeader';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';

import NavTabs from '@/components/NavTabs';

import nanoRef from '@/utils/nanoRef';

export default {
  name: 'CampaignEdit',
  mixins: [broker],
  components: { SqrPageHeader, NavTabs, SqrButton, SqrErrorBanner },
  props: {
    cid: String
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('campaign', { campaign: 'doc' }),
    ...mapState('campaign', ['loading', 'loadError', 'path']),
    ...mapState('remover', ['removing']),
    tabs() {
      const bid = this.bid;
      const cid = this.cid;
      return [
        {
          id: 'recipients',
          label: 'Destinataires',
          to: { name: 'campaign-recipients', params: { bid, cid } }
        },
        {
          id: 'email',
          label: 'e-mail',
          to: { name: 'campaign-email', params: { bid, cid } }
        }
      ];
    }
  },
  data() {
    return {
      cloning: false,
      sending: false,
      sendError: null
    };
  },
  mounted() {
    const bid = this.bid;
    const cid = this.cid;
    this.sub({
      path: `brokers/${bid}/campaigns/${cid}`
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const { bid, cid } = to.params;
    this.sub({ path: `brokers/${bid}/campaigns/${cid}` });
    next();
  },
  methods: {
    ...mapActions('campaign', ['sub', 'unsub']),
    ...mapActions('updater', ['update']),
    ...mapActions('remover', ['remove']),
    fieldSet(name, value) {
      this.update({ path: this.path, doc: { [name]: value } });
    },
    async removeConfirm() {
      if (!confirm('Supprimer la campagne ?')) return;
      await this.remove({ path: this.path });
      const bid = this.bid;
      this.$router.push({ name: 'campaigns', params: { bid } });
    },
    async clone() {
      const name = prompt('Nom de la nouvelle campagne ?');
      if (!name) return;
      try {
        this.cloning = true;
        const bid = this.bid;
        const campaign = {
          ...omit(['id', 'path', 'name'], this.campaign),
          name
        };
        const res = await this.$db()
          .collection(`brokers/${bid}/campaigns`)
          .add(campaign);
        this.$router.push({
          name: 'campaign-email',
          params: { bid, cid: res.id }
        });
      } catch (error) {
        alert('erreur lors de la copie');
        return Promise.reject(error);
      } finally {
        this.cloning = false;
      }
    },
    async rename() {
      const name = prompt('Renommer le modèle', this.campaign.name);
      if (name) {
        await this.update({ path: this.path, doc: { name } });
      }
    },
    async send() {
      if (!this.campaign) return;
      try {
        this.sendError = null;
        this.sending = true;
        const bid = this.bid;

        const recipients = this.campaign.recipients ?? [];
        const subjectT = compile(this.campaign.subject ?? '');
        const textT = compile(this.campaign.text ?? '');

        const batch = this.$db().batch();
        const col = this.$db().collection(`brokers/${bid}/emails`);
        recipients.forEach(recipient => {
          batch.set(col.doc(), {
            to: `${recipient.name} <${recipient.email}>`,
            replyTo: this.user?.email ?? '',
            message: {
              subject: subjectT(recipient),
              text: textT(recipient)
            }
          });
        });
        await batch.commit();
      } catch (error) {
        this.sendError = error;
        return Promise.reject(error);
      } finally {
        this.sending = false;
      }
    }
  }
};
</script>
